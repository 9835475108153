import React from 'react';
import Badge from "reactstrap/es/Badge";
import {
  AlertCircle, AlertTriangle, Check,
  CheckCircle,
  CheckSquare,
  Clock, DownloadCloud,
  Pause, Play,
  Repeat,
  RotateCw, Shield, Sunrise,
  ThumbsDown,
  ThumbsUp, Trash2, X
} from "react-feather";
import Chip from "../components/@vuexy/chips/ChipComponent";
import {Alert, Progress} from "reactstrap";
import {toast} from "react-toastify";
import {refreshPermissions} from "./Can";
import axios from "axios";

const Colors = {
  red: "#cc3333",
  orange: "#ff6633",
  yellow: "#ffcc33",
  green: "#33cc33",
  blue: "#007700"
};
const ColorScale = (percentage) => {
  if (percentage < 20) {
    return 'danger';
  }
  if (percentage >= 20 && percentage < 40) {
    return 'warning';
  }
  if (percentage >= 40 && percentage < 60) {
    return 'success';
  }
  if (percentage >= 60 && percentage < 80) {
    return 'primary';
  }
  if (percentage >= 80) {
    return 'info';
  }
}


const ExtractSelectOptionValues = (array) => {
  let results = [];
  array.map(x => results[results.length] = x.value)
  return results;
}

const SetErrorkeys = (fields) => {
  for (const key in fields) {
    fields[key] = false
  }
  return fields;
}

const CatchError = (e) => {
  const errorCode = e.message.replace('Request failed with status code ', '')
  if (errorCode === '403') {
    return refreshPermissions().finally(() => toast.error('Sorry, Restricted Action'));
  }
  if (errorCode === '401') {
    return refreshPermissions().finally(() => toast.warn('Sorry, Authentication failed'))
  }
  if (errorCode === '422') {
    return toast.error('Please fill in all the * fields')
  }
  if (errorCode === '404') {
    return axios.get('/optimize')
  }

  if (errorCode === '500') {
    return toast.warn('There was an error on the server, please try again.')
  }

  // console.log(e.message)
  return toast.error(e.message);
}

const HandleSelectOptions = (array, label, value) => {
  let options = [];
  try {
    array.map(x => options[options.length] = {label: x[label], value: x[value]})
    return options;
  } catch (e) {
    return [];
  }
}

const CollectValues = (array) => {

  if (array === null) return [];
  let ids = [];
  array.map(x =>ids[ids.length] = x.value)
  return ids;
}

const DisplayColors = (status) => {
  let array = {
    approved: 'success',
    rejected: 'danger',
    complied: 'success',
    breached: 'danger',
    active: 'success',
    inactive: 'warning',
    pending: 'warning',
    deleted: 'dark'
  }
  return array[status.toLowerCase()];
}

const HeatMapColorScale = {
  gross_risk: {
    Low: Colors.blue,
    Minimal: Colors.green,
    Moderate: Colors.yellow,
    High: Colors.orange,
    Severe: Colors.red
  },
  net_risk: {
    Low: Colors.blue,
    Minimal: Colors.green,
    Moderate: Colors.yellow,
    High: Colors.orange,
    Severe: Colors.red
  },
  control_ratings: {
    Excellent: Colors.blue,
    Good: Colors.green,
    Moderate: Colors.yellow,
    Weak: Colors.orange,
    Poor: Colors.red
  }
};


const EvaluationBadge = ({type, rating}) => {
  return <Badge color={'red'} style={{backgroundColor: HeatMapColorScale[type][rating]}}
                className="mr-1 mb-1 badge-square">{rating.toUpperCase()}</Badge>

}


const BadgeStatus = ({status, breach, size}) => {
  let Size = 12
  let array = {
    approved: 'success',
    "in progress": 'danger',
    "over": 'success',
    blocked: 'danger',
    rejected: 'danger',
    complied: 'success',
    breached: 'danger',
    repealed: 'light',
    active: 'success',
    inactive: 'warning',
    pending: 'warning',
    proposed: 'warning',
    "in place": 'success',
    deleted: 'dark',
    complete: 'success',
    incomplete: 'warning',

  }
  let icon = {
    proposed: <Clock size={Size}/>,
    "in place": <ThumbsUp size={Size}/>,
    approved: <ThumbsUp size={Size}/>,
    "in progress": <AlertTriangle size={Size}/>,
    "over": <Check size={Size}/>,
    rejected: <ThumbsDown size={Size}/>,
    blocked: <Shield size={Size}/>,
    complied: <CheckSquare size={Size}/>,
    repealed: <X size={Size}/>,
    breached: <X size={Size}/>,
    pending: <Clock size={Size}/>,
    deleted: <Trash2 size={Size}/>,
    active: <Play size={Size}/>,
    inactive: <Pause size={Size}/>,
    complete: <Check size={Size}/>,
    incomplete: <Clock size={Size}/>,
  }
  if (breach) {
    let array = {approved: 'danger', rejected: 'success', pending: 'warning'}
    let icon = {
      approved: <AlertTriangle size={Size}/>,
      rejected: <Sunrise size={Size}/>,
      pending: <Clock size={Size}/>
    }
    return (<Badge color={`light-` + array[status.toLowerCase()]}
                   className={'text-uppercase'}>{icon[status.toLowerCase()]} {status}</Badge>)
  }

  return (<Badge color={`light-` + array[status.toLowerCase()]}
                 className={'text-uppercase'}>{icon[status.toLowerCase()]} {status}</Badge>)
}

const BadgeRepeat = ({repeat, frequency, event_title, size}) => {

  let Size = size ? size : 14
  const icon = {
    "One Off": <CheckCircle size={Size}/>,
    "Once": <CheckCircle size={Size}/>,
    "Periodic": <Repeat size={Size}/>,
    "Cyclical": <Repeat size={Size}/>,
    "Continuous": <RotateCw size={Size}/>,
  }
  if (repeat === 'Continuous') {
    return <span>{icon[repeat]} Continuous</span>
  }
  if (repeat === 'Periodic' || repeat === 'Cyclical') {
    return <span>{icon[repeat]} {frequency.toUpperCase()}</span>
  }
  if (repeat === 'One Off' || repeat === 'Once') {
    return <span>{icon[repeat]} {repeat}</span>
  }
  return <>Nothing</>;
}

const NumberShortFormat = (number) => {

  if (!number) return 0;
  number = parseInt(number);
  if (!number) return 0;
  if (number > 1000 && number < 1000000) return Math.round(number / 1000) + " K";
  if (number >= 1000000) return Math.round(number / 1000000) + " M";
}

const ChipText = ({text, color}) => {
  return <Chip className="mr-1 mb-1"
               avatarColor={color}

               color={color}
               text={text}/>
}

const firstChars = (name) => {
  let names = name.split(' ');
  let chars = "";
  names.map(n =>  chars += n.slice(0, 1).toUpperCase())
  return chars;
}

const DisplayHTTP = ({response}) => {
  if (!response) return false;
  if (response.status === 200) {
    return <><Alert><p>{response.statusText}</p></Alert>
      {/*<pre>{JSON.stringify(response)}</pre>*/}
    </>
  } else {
    return <><Alert color={'danger'}>
      <AlertCircle/> {response.status}
      <p>{response.statusText}</p></Alert>
      {/*<pre>{JSON.stringify(response)}</pre>*/}
    </>
  }
}

const PriorityBadge = ({priority}) => <Badge color={PriorityColors(priority)}
                                             className={'text-uppercase'}> {priority}</Badge>
const ProgressBar = ({value, negative}) => {
  const colors = ['danger', 'warning', 'success'];
  let index = 0;
  if (value < 50) index = 0;
  if (value >= 50 && value < 100) index = 1;
  if (value === 100) index = 2;

  index = (negative === true) ? 2 - index : index;
  // return colors[index] ;
  return <Progress
    className="progress-md"
    color={colors[index]}
    value={value}
  />

}

const sizeCalc = (bytes, decimals) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return '' + parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
}



const DisplayDocument = ({id, title, size, filesize}) => {
  if (!id) return false;


  return <span className="cursor-pointer" title={size && sizeCalc(filesize, 0)}>
    <DownloadCloud size={size ? size : 14}/>{` ${title}`} </span>
}


const PriorityColors = (priority) => {
  let array = {
    High: "danger",
    Medium: "warning",
    Low: "info"
  }
  return array[priority];
}
const PrettyPrintJson = ({data}) => (<div><pre>{
  JSON.stringify(data, null, 2)}</pre>
</div>);
let primary = "#7367F0",
  success = "#28C76F",
  danger = "#EA5455",
  warning = "#FF9F43",
  info = "#00cfe8"

let themeColors = [primary, success, danger, warning, info]
let colorOptions = {primary, success, danger, warning, info};

const HandleInputChange = (label, element, required, fields, errors) => {
  let value = (element && element.target) ? element.target.value : element;
  fields[label] = value;
  if (required) errors[label] = (value !== null && value.length !== 0) ? false : true
  return {fields, errors};
}

const ValidateFormFields = async (fields, errors, requiredFields) => {
  let errorFlag = false;
  requiredFields.map(key => {
    const value = fields[key]
    errors[key] = ((typeof value !== 'undefined') && value !== null && value.length !== 0) ? false : true;
    return errors
  });
  return {errors, errorFlag};
}
const ErrorHelper = ({show, select, value}) => <> {
  show && <span style={{borderRadius: 5}} className={'bg-danger text-white p-50 mt-25'}>
    Required <small>{value}</small>
    </span>
} </>

export {
  themeColors,
  colorOptions,
  ColorScale,
  CatchError,
  HandleSelectOptions,
  ExtractSelectOptionValues,
  BadgeStatus,
  NumberShortFormat,
  BadgeRepeat,
  CollectValues,
  firstChars,
  ChipText,
  DisplayColors,
  DisplayHTTP,
  PriorityColors,
  PriorityBadge,
  EvaluationBadge,
  ProgressBar,
  PrettyPrintJson,
  DisplayDocument,
  HeatMapColorScale,
  sizeCalc,
  SetErrorkeys,
  HandleInputChange,
  ValidateFormFields,
  ErrorHelper
}


