import {
  AUTH_CHECK_FAILURE,
  AUTH_CHECK_REQUEST,
  AUTH_CHECK_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_FAILURE,
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS, AUTH_PERMISSIONS_FAILURE,
  AUTH_PERMISSIONS_REQUEST,
  AUTH_PERMISSIONS_SUCCESS, AUTH_RESET_PASSWORD_FAILURE, AUTH_RESET_PASSWORD_REQUEST, AUTH_RESET_PASSWORD_SUCCESS,
  AUTH_USER_FAILURE,
  AUTH_USER_REQUEST,
  AUTH_USER_SUCCESS
} from "../../types/authTypes";

const auth = JSON.parse(localStorage.getItem('auth'))

const initState = {
  loading: false,
  auth: auth,
  user :auth && auth.user,
  permissions: auth && auth.permissions,
  loggedIn: auth ? true : false,
  token: auth && auth.token,
  error :false,
  message : false,
  messageType: 'info'
}
const AuthReducer = (state = initState, action) => {
  switch (action.type) {
    case AUTH_USER_REQUEST : {return {...state, loading : true, message: "Checking if you are authenticated", messageType: 'info'}}
    case AUTH_USER_SUCCESS : {return {...state, loading : false,  user: action.payload, message: "Welcome Back", messageType: 'success' }}
    case AUTH_USER_FAILURE : {return {...state, loading : false, error: action.payload, message: action.payload.message, messageType: 'danger'}}

    case AUTH_LOGIN_REQUEST : {return {...state, loading: true, message: 'Authenticating, please wait', messageType: 'info'}}
    case AUTH_LOGIN_SUCCESS : {return {...state, loading: false, loggedIn: true, token: action.payload.token, user: action.payload, permissions: action.payload.permissions}}
    case AUTH_LOGIN_FAILURE : {return {...state, loading: false, loggedIn: false, error: action.payload, message: action.payload.message, messageType: 'danger'}}

    case AUTH_LOGOUT_REQUEST : {return {...state, loading: true, loggedIn: false, token: false, user: false, permissions: []}}
    case AUTH_LOGOUT_SUCCESS : {return {...state, loading: false, loggedIn: false, token: false, user: false, permissions: []}}
    case AUTH_LOGOUT_FAILURE : {return {...state, loading: false, loggedIn: false, token: false, user: false, permissions: []}}

    case AUTH_CHECK_REQUEST : {return {...state, loading: true, message: "Checking if you are authenticated", messageType: 'info'}}
    case AUTH_CHECK_SUCCESS : {return {...state, loading: false, user: action.payload, permissions: action.payload.permissions}}
    case AUTH_CHECK_FAILURE : {return {...state, loading: false, error: action.payload, message: action.payload.message, messageType: 'danger'}}

    case AUTH_PERMISSIONS_REQUEST : {return {...state, loading: true}}
    case AUTH_PERMISSIONS_SUCCESS : {return {...state, loading: false, permissions: action.payload}}
    case AUTH_PERMISSIONS_FAILURE : {return {...state, loading: false, error: action.payload}}

    case AUTH_RESET_PASSWORD_REQUEST : {return {...state, loading: true}}
    case AUTH_RESET_PASSWORD_SUCCESS : {return {...state, loading: false}}
    case AUTH_RESET_PASSWORD_FAILURE : {return {...state, loading: false}}

    default: return state;
  }
}


export default AuthReducer;
