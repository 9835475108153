import {
  REGISTER_DELETE_FAILURE,
  REGISTER_DELETE_REQUEST, REGISTER_DELETE_SUCCESS, REGISTER_FIND_FAILURE, REGISTER_FIND_REQUEST, REGISTER_FIND_SUCCESS,
  REGISTER_FORM_FAILURE,
  REGISTER_FORM_REQUEST, REGISTER_FORM_SUCCESS,
  REGISTER_GET_FAILURE,
  REGISTER_GET_REQUEST,
  REGISTER_GET_SUCCESS, REGISTER_SUBMIT_FAILURE,
  REGISTER_SUBMIT_REQUEST, REGISTER_SUBMIT_SUCCESS
} from "../../types/registerTypes";

const initState = {
  loading: false,
  registers : [],
  details:{},
  form: {},
  error: false
}

const RegisterReducer = (state = initState, action) => {
  switch (action.type){

    case REGISTER_GET_REQUEST : {return {...state, loading: true,error: false }}
    case REGISTER_GET_SUCCESS : {return {...state, loading: false,error: false, registers: action.payload }}
    case REGISTER_GET_FAILURE : {return {...state, loading: true,error: action.payload }}

    case REGISTER_SUBMIT_REQUEST : {return {...state, loading: true,error: false }}
    case REGISTER_SUBMIT_SUCCESS : {return {...state, loading: false,error: false }}
    case REGISTER_SUBMIT_FAILURE : {return {...state, loading: true,error: action.payload }}

    case REGISTER_DELETE_REQUEST : {return {...state, loading: true,error: false }}
    case REGISTER_DELETE_SUCCESS : {return {...state, loading: false,error: false }}
    case REGISTER_DELETE_FAILURE : {return {...state, loading: true,error: action.payload }}

    case REGISTER_FORM_REQUEST : {return {...state, loading: true,error: false }}
    case REGISTER_FORM_SUCCESS : {return {...state, loading: false,form: action.payload }}
    case REGISTER_FORM_FAILURE : {return {...state, loading: true,error: action.payload }}

    case REGISTER_FIND_REQUEST: {
      const {details} = state;
      const record = state.details[action.payload] !== 'undefined' ? state.details : Object.assign(details, {[action.payload]: false})
      return {...state, loading: true, details: record}
    }
    case REGISTER_FIND_SUCCESS: {
      const {details} = state;
      const record = Object.assign(details, {[action.payload.info.id]: action.payload})
      return {...state, loading: false, details: record}
    }
    case REGISTER_FIND_FAILURE: {
      return {...state, loading: false, error: action.payload}
    }


    default : return state;
  }
}


export default RegisterReducer
