import {
  EVENT_GET_FAILURE,
  EVENT_GET_REQUEST,
  EVENT_GET_SUCCESS,
  EVENT_FIND_REQUEST,
  EVENT_FIND_SUCCESS,
  EVENT_FIND_FAILURE,
  EVENT_CANCEL_REQUEST,
  EVENT_CANCEL_SUCCESS,
  EVENT_CANCEL_FAILURE,
  EVENT_DISMISS_REQUEST,
  EVENT_DISMISS_SUCCESS,
  EVENT_DISMISS_FAILURE,
  EVENT_IMPORT_REQUEST,
  EVENT_IMPORT_SUCCESS,
  EVENT_IMPORT_FAILURE,
  EVENT_ALL_REQUEST,
  EVENT_ALL_SUCCESS,
  EVENT_ALL_FAILURE,
  EVENT_UPDATE_REQUEST,
  EVENT_UPDATE_SUCCESS,
  EVENT_UPDATE_FAILURE,
  EVENT_OBLIGATIONS_REQUEST,
  EVENT_OBLIGATIONS_SUCCESS,
  EVENT_OBLIGATIONS_FAILURE
} from "../../types/eventTypes";


const initState = {
  loading: false,
  response: null,
  events: [],
  all: [],
  details: {},
  error: false,
  obligations: []
}

const EventReducer = (state = initState, action) => {

  switch (action.type) {

    case EVENT_UPDATE_REQUEST :
      return {...state, loading: true}
    case EVENT_UPDATE_SUCCESS:
      return {...state, loading: false}
    case EVENT_UPDATE_FAILURE:
      return {...state, loading: false, error: action.payload}

    case EVENT_GET_REQUEST :
      return {...state, loading: true}
    case EVENT_GET_SUCCESS:
      return {...state, loading: false, events: action.payload}
    case EVENT_GET_FAILURE:
      return {...state, loading: false, error: action.payload}

    case EVENT_ALL_REQUEST :
      return {...state, loading: true}
    case EVENT_ALL_SUCCESS:
      return {...state, loading: false, all: action.payload}
    case EVENT_ALL_FAILURE:
      return {...state, loading: false, error: action.payload}

    case EVENT_OBLIGATIONS_REQUEST :
      return {...state, loading: true}
    case EVENT_OBLIGATIONS_SUCCESS:
      return {...state, loading: false, obligations: action.payload}
    case EVENT_OBLIGATIONS_FAILURE:
      return {...state, loading: false, error: action.payload}

    case EVENT_FIND_REQUEST : {
      const {details} = state;
      const record = state.details[action.payload] !== 'undefined' ? state.details : Object.assign(details, {[action.payload]: false})
      return {...state, loading: true, details: record}
    }
    case EVENT_FIND_SUCCESS: {
        const {details} = state;
        const record = Object.assign(details, {[action.payload.info.id]: action.payload})
        return {...state, loading: false, details: record}
    }
    case EVENT_FIND_FAILURE:
      return {...state, loading: false, error: action.payload}

    case EVENT_CANCEL_REQUEST :
      return {...state, loading: true}
    case EVENT_CANCEL_SUCCESS:
      return {...state, loading: false}
    case EVENT_CANCEL_FAILURE:
      return {...state, loading: false, error: action.payload}

    case EVENT_DISMISS_REQUEST :
      return {...state, loading: true}
    case EVENT_DISMISS_SUCCESS:
      return {...state, loading: false}
    case EVENT_DISMISS_FAILURE:
      return {...state, loading: false, error: action.payload}


    case EVENT_IMPORT_REQUEST :
      return {...state, loading: true}
    case EVENT_IMPORT_SUCCESS:
      return {...state, loading: false}
    case EVENT_IMPORT_FAILURE:
      return {...state, loading: false, error: action.payload}

    default :
      return state
  }
}

export default EventReducer;
