import React, {useContext} from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Button
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import axios from "axios"
import * as Icon from "react-feather"
import {history} from "../../../history"
import {AuthContext} from "../../../AuthContext";
import {CatchError, firstChars} from "../../../utility/General";
import Avatar from "../../../components/@vuexy/avatar/AvatarComponent";
import moment from "moment";

const handleNavigation = (e, path) => {
  e.preventDefault()
  history.push(path)
}

const UserDropdown = props => {
  const {logout} = useContext(AuthContext);
  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/user/account")}
      >
        <Icon.User size={14} className="mr-50"/>
        <span className="align-middle">Edit Profile</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/user/messages")}
      >
        <Icon.Mail size={14} className="mr-50"/>
        <span className="align-middle">My Messages</span>
      </DropdownItem>

      <DropdownItem divider/>
      <DropdownItem
        tag="a"
        onClick={logout}
      >
        <Icon.Power size={14} className="mr-50"/>
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      navbarSearch: false,
      langDropdown: false,
      suggestions: [],
      user: false,
      attempt: 0,
      loading: false,
      messages: [],
      show: false,
    }
  }

  componentDidMount() {
    console.log(this.props.user, 'Navbar')

    setTimeout((() => {
      this._getUser();
      this._messages()
    }), 2000);

  }

  _getUser = async () => {

    await axios.get('./app/user')
      .then(res => {
        this.setState({user: res.data})
      })
      .catch(e => {
        // CatchError(e);
        if (this.state.attempt < 2) {
          this.setState({attempt: this.state.attempt + 1}, this._getUser)
        }
      })
  }

  _messages = () => {
    let url = 'app/global/account/messages/get';
    this.setState({loading: true});
    axios.get(url)
      .then(res => this.setState({messages: res.data}))
      .catch((e) => {
        if(e.message === ''){}
      })
      .finally(() => this.setState({loading: false}))
  }

  _read = (message) => {
    let url = 'app/global/account/messages/read';
    this.setState({openMessage: message});
    let params = {id: message.id}

    axios.get(url, {params})
      .then(res => this.setState({messages: res.data}))
      .catch(CatchError)
      .finally(this._messages)
  }


  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }

  render() {
    const {user, openMessage, loading, messages} = this.state;
    return (
      <><ul  className="nav navbar-nav navbar-nav-user float-right">

        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" onClick={() => this._messages()} className="nav-link nav-link-label">

            <Icon.Mail size={21}/>
            {messages && messages.new > 0 && <Badge pill color="danger" className="badge-up">
              {messages.new}
            </Badge>}
          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                <h3 className="text-white">
                  {!loading && messages && messages.new}
                  {" "}
                  {loading && <Spinner size={'md'}
                  />}
                  New</h3>
                <span className="notification-title">Messages</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false
              }}
            >
              {messages && messages.data && (messages.data).map(message => <div
                key={`messages-nav-${message.id}`}
                className="d-flex justify-content-between">
                <Media onClick={() => this._read(message)} className="d-flex align-items-start">

                  <Media body>
                    {(!openMessage || openMessage.id !== message.id) &&
                    <Media heading className={`${(message.read === 'No' ? message.color : null)} media-heading`}
                           tag="h6">
                      {message.title}
                    </Media>}
                    <p className={message.read === 'No' ? `notification-text` : ` notification-text bold`}>
                      {message.subject}
                    </p>
                    {openMessage && openMessage.id === message.id && <div
                      style={{lineHeight: 1.3}}
                      dangerouslySetInnerHTML={{
                        __html: openMessage.body
                      }}></div>}
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime={message.created_at}
                    >
                      {moment(message.created_at).fromNow()}
                    </time>
                  </small>
                </Media>
              </div>)}


            </PerfectScrollbar>

          </DropdownMenu>
        </UncontrolledDropdown>
        {user && <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" onClick={this._getUser} className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
                      <span className="user-name text-bold-600">
                      {user.name}
                      </span>
              <span className="user-status">{user.status}</span>
            </div>
            <span data-tour="user">
            <Avatar img={user.avatar} size={40} color={'primary'} content={firstChars(user.name)}/>
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>}

        <Modal
          isOpen={openMessage}
          toggle={() => this.setState({openMessage: false})}
          className="modal-dialog-centered"
        >
          <ModalHeader
            toggle={() => this.setState({openMessage: false})}
          >
            {openMessage && openMessage.subject}
          </ModalHeader>
          <ModalBody>
            {openMessage && <div
              dangerouslySetInnerHTML={{
                __html: openMessage.body
              }}></div>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.setState({openMessage: false})}>
              OK
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </ul>

      </>
    )
  }
}

export default NavbarUser

/*
*
*


* */
