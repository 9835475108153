import React from "react"
import * as Icon from "react-feather"

const Settings = [
  {
    id: "user",
    title: "User",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/settings/user"
  },
  {
    id: "roles",
    title: "Roles",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/settings/role"
  },
  {
    id: "source-repository",
    title: "Repository",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/repository"
  },
  // {
  //   id: "entities",
  //   title: "My Company",
  //   type: "item",
  //   icon: <Icon.Circle size={12}/>,
  //   permissions: ["admin", "editor"],
  //   navLink: "/entities"
  // },
  // {
  //   id: "department",
  //   title: "Departments",
  //   type: "item",
  //   icon: <Icon.Circle size={12}/>,
  //   permissions: ["admin", "editor"],
  //   navLink: "/department"
  // },
  {
    id: "authorities",
    title: "Authorities",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/compliance/authority"
  },
  {
    id: "templates",
    title: "Templates",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/settings/templates"
  },
  // {
  //   id: "integrations",
  //   title: "Integrations",
  //   type: "item",
  //   icon: <Icon.Circle size={12}/>,
  //   permissions: ["admin", "editor"],
  //   navLink: "/settings/integration"
  // },
  {
    id: "logs",
    title: "Logs",
    type: "item",
    icon: <Icon.Circle size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/settings/logs"
  }
];
const mainMenu = [
  {
    id: "compliance-dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20}/>,
    permissions: ["admin", "editor"],
    navLink: "/"
  },
  {
    id: "compliance-registers",
    title: "Registers",
    type: "item",
    icon: <Icon.Briefcase size={20}/>,
    permissions: ["admin", "editor"],
    navLink: "/compliance/register/:id"
  },
  {
    id: "compliance-requirements",
    title: "Requirements",
    type: "item",
    icon: <Icon.Folder size={20}/>,
    permissions: ["admin", "editor"],
    navLink: "/compliance/requirement"
  },
  {
    id: "obligations",
    title: "Obligations",
    type: "item",
    icon: <Icon.Activity size={20}/>,
    permissions: ["admin", "editor"],
    navLink: "/compliance/obligation"
  },
  {
    id: "events",
    title: "Events",
    type: "item",
    icon: <Icon.Zap size={20}/>,
    permissions: ["admin", "editor"],
    navLink: "/events"
  },

  {
    id: "my-company",
    title: "My Company",
    type: "item",
    icon: <Icon.Sunrise size={12}/>,
    permissions: ["admin", "editor"],
    navLink: "/company"
  },
  {
    id: "settings",
    title: "Settings",
    type: "collapse",
    icon: <Icon.Settings size={20}/>,
    permissions: ["admin", "editor"],
    children: Settings
  }
]
const navigationConfig = mainMenu;

export default navigationConfig
