
import {
  COMPLIANCE_ACTIVATION_FAILURE,
  COMPLIANCE_ACTIVATION_REQUEST, COMPLIANCE_ACTIVATION_SUCCESS,
  COMPLIANCE_DELETE_FAILURE,
  COMPLIANCE_DELETE_REQUEST,
  COMPLIANCE_DELETE_SUCCESS,
  COMPLIANCE_FIND_FAILURE,
  COMPLIANCE_FIND_REQUEST,
  COMPLIANCE_FIND_SUCCESS,
  COMPLIANCE_FORM_FAILURE,
  COMPLIANCE_FORM_REQUEST,
  COMPLIANCE_FORM_SUCCESS,
  COMPLIANCE_GET_FAILURE,
  COMPLIANCE_GET_REQUEST,
  COMPLIANCE_GET_SUCCESS,
  COMPLIANCE_SUBMIT_FAILURE,
  COMPLIANCE_SUBMIT_REQUEST,
  COMPLIANCE_SUBMIT_SUCCESS
} from "../../types/complianceTypes";

const initState = {
  loading: false,
  compliance_requirements : [],
  details:{},
  form: {},
  error: false
}

const ComplianceReducer = (state = initState, action) => {
  switch (action.type){

    case COMPLIANCE_GET_REQUEST : {return {...state, loading: true,error: false }}
    case COMPLIANCE_GET_SUCCESS : {return {...state, loading: false,error: false, compliance_requirements: action.payload }}
    case COMPLIANCE_GET_FAILURE : {return {...state, loading: true,error: action.payload }}

    case COMPLIANCE_SUBMIT_REQUEST : {return {...state, loading: true,error: false }}
    case COMPLIANCE_SUBMIT_SUCCESS : {return {...state, loading: false,error: false }}
    case COMPLIANCE_SUBMIT_FAILURE : {return {...state, loading: true,error: action.payload }}

    case COMPLIANCE_ACTIVATION_REQUEST : {return {...state, loading: true,error: false }}
    case COMPLIANCE_ACTIVATION_SUCCESS : {return {...state, loading: false,error: false }}
    case COMPLIANCE_ACTIVATION_FAILURE : {return {...state, loading: true,error: action.payload }}

    case COMPLIANCE_DELETE_REQUEST : {return {...state, loading: true,error: false }}
    case COMPLIANCE_DELETE_SUCCESS : {return {...state, loading: false,error: false }}
    case COMPLIANCE_DELETE_FAILURE : {return {...state, loading: true,error: action.payload }}

    case COMPLIANCE_FORM_REQUEST : {return {...state, loading: true,error: false }}
    case COMPLIANCE_FORM_SUCCESS : {return {...state, loading: false,form: action.payload }}
    case COMPLIANCE_FORM_FAILURE : {return {...state, loading: true,error: action.payload }}

    case COMPLIANCE_FIND_REQUEST: {
      const {details} = state;
      const record = state.details[action.payload] !== 'undefined' ? state.details : Object.assign(details, {[action.payload]: false})
      return {...state, loading: true, details: record}
    }
    case COMPLIANCE_FIND_SUCCESS: {
      const {details} = state;
      const record = Object.assign(details, {[action.payload.info.id]: action.payload})
      return {...state, loading: false, details: record}
    }
    case COMPLIANCE_FIND_FAILURE: {
      return {...state, loading: false, error: action.payload}
    }


    default : return state;
  }
}


export default ComplianceReducer
