import React, {useState, useEffect} from 'react';
import axios from "axios";
import cookie from "react-cookies";


export const AuthContext = React.createContext();

export const AuthProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [response, setResponse] = useState({show: false, color: 'info', message: '', data: null});
  const [checkingAuth] = useState(false);

  const setResMessage = (show, color, message, data) => setResponse({show, color, message, data});

  const logout = () => {
    setResMessage(true, 'warning', 'You have successfully logged outs...');

    cookie.save('permissions', false, {path: '/'});
    cookie.save('AuthToken', false, {path: '/'});
    cookie.save('auth', false, {path: '/'});
    cookie.save('loggedIn', false, {path: '/'});
    setUser(false);
    localStorage.clear();
    axios.defaults.headers.common = null;
  }

  const checkUser = () => {
    // if (cookie.load('loggedIn')) return true;
    if (cookie.load('loggedIn')) {
      setUser(cookie.load('auth'))
      axios.defaults.headers.common = cookie.load('AuthToken')
    }
    // setCheckingAuth(true)
    axios.get('/app/user').then(res => {
      setUser(res.data);
      cookie.save('permissions', res.data.permissions, {path: '/'})
    }).catch((e) => {
      setUser(false);
      if (e.status === 401) {
        logout()
      }
    })
      // .finally(() => setCheckingAuth(false))
  }

  const setCookies = async (res) => {
    setResMessage(true, 'success', 'Authentication Successful, Redirecting...');

    await cookie.save('permissions', res.data.permissions, {path: '/'})
    await cookie.save('auth', res.data.user, {path: '/'})
    await cookie.save('AuthToken', {'Authorization': `Bearer ${res.data.token}`}, {path: '/'})
    axios.defaults.headers.common = cookie.load('AuthToken')
    await setUser(res.data);
    await cookie.save('loggedIn', true, {path: '/'});
  }

  const login = async (email, password) => {
    let url = 'auth/login';
    let data = {email, password}
    setResMessage(true, 'info', 'Authenticating, Please wait....');
    // toast.success("Authenticating");
    await axios.post(url, data).then(async (res) => {
      await setCookies(res);
    })
      // .catch(e => setResMessage(true, 'danger', e.data.message))
      .catch(e => setResMessage(true, 'danger', e.response.data.message, e.response.data.errors))
      .finally(() => {
        // if (user) window.location.reload();
      })
  }
  useEffect(() => {
  }, []);
  // checkUser();
  return <AuthContext.Provider
    value={{checkUser, user, setUser, login, checkingAuth, logout, response}}>{children}</AuthContext.Provider>
}
