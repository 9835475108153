export const COMPLIANCE_SUBMIT_REQUEST = 'COMPLIANCE_SUBMIT_REQUEST'
export const COMPLIANCE_SUBMIT_SUCCESS = 'COMPLIANCE_SUBMIT_SUCCESS'
export const COMPLIANCE_SUBMIT_FAILURE = 'COMPLIANCE_SUBMIT_FAILURE'

export const COMPLIANCE_GET_REQUEST = 'COMPLIANCE_GET_REQUEST'
export const COMPLIANCE_GET_SUCCESS = 'COMPLIANCE_GET_SUCCESS'
export const COMPLIANCE_GET_FAILURE = 'COMPLIANCE_GET_FAILURE'

export const COMPLIANCE_FORM_REQUEST = 'COMPLIANCE_FORM_REQUEST'
export const COMPLIANCE_FORM_SUCCESS = 'COMPLIANCE_FORM_SUCCESS'
export const COMPLIANCE_FORM_FAILURE = 'COMPLIANCE_FORM_FAILURE'

export const COMPLIANCE_FIND_REQUEST = 'COMPLIANCE_FIND_REQUEST'
export const COMPLIANCE_FIND_SUCCESS = 'COMPLIANCE_FIND_SUCCESS'
export const COMPLIANCE_FIND_FAILURE = 'COMPLIANCE_FIND_FAILURE'

export const COMPLIANCE_DELETE_REQUEST = 'COMPLIANCE_DELETE_REQUEST'
export const COMPLIANCE_DELETE_SUCCESS = 'COMPLIANCE_DELETE_SUCCESS'
export const COMPLIANCE_DELETE_FAILURE = 'COMPLIANCE_DELETE_FAILURE'

export const COMPLIANCE_ACTIVATION_REQUEST = 'COMPLIANCE_ACTIVATION_REQUEST'
export const COMPLIANCE_ACTIVATION_SUCCESS = 'COMPLIANCE_ACTIVATION_SUCCESS'
export const COMPLIANCE_ACTIVATION_FAILURE = 'COMPLIANCE_ACTIVATION_FAILURE'

