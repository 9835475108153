import {DASHBOARD_GET_FAILURE, DASHBOARD_GET_REQUEST, DASHBOARD_GET_SUCCESS} from "../../types/dashboardTypes";

const initState = {
  data: false,
  loading: false,
  error: false
}


const Dashboard = (state = initState, action)=> {
  switch (action.type) {
    case  DASHBOARD_GET_REQUEST : {return {...state, loading: true}}
    case  DASHBOARD_GET_SUCCESS : {return {...state, loading: false, data: action.payload}}
    case  DASHBOARD_GET_FAILURE : {return {...state, loading: false, error: action.payload}}
    default : return state;
  }
}


export default Dashboard;
