export const EVENT_UPDATE_REQUEST = 'EVENT_UPDATE_REQUEST';
export const EVENT_UPDATE_SUCCESS = 'EVENT_UPDATE_SUCCESS';
export const EVENT_UPDATE_FAILURE = 'EVENT_UPDATE_FAILURE';

export const EVENT_GET_REQUEST = 'EVENT_GET_REQUEST';
export const EVENT_GET_SUCCESS = 'EVENT_GET_SUCCESS';
export const EVENT_GET_FAILURE = 'EVENT_GET_FAILURE';

export const EVENT_OBLIGATIONS_REQUEST = 'EVENT_OBLIGATIONS_REQUEST';
export const EVENT_OBLIGATIONS_SUCCESS = 'EVENT_OBLIGATIONS_SUCCESS';
export const EVENT_OBLIGATIONS_FAILURE = 'EVENT_OBLIGATIONS_FAILURE';

export const EVENT_ALL_REQUEST = 'EVENT_ALL_REQUEST';
export const EVENT_ALL_SUCCESS = 'EVENT_ALL_SUCCESS';
export const EVENT_ALL_FAILURE = 'EVENT_ALL_FAILURE';

export const EVENT_FIND_REQUEST = 'EVENT_FIND_REQUEST';
export const EVENT_FIND_SUCCESS = 'EVENT_FIND_SUCCESS';
export const EVENT_FIND_FAILURE = 'EVENT_FIND_FAILURE';

export const EVENT_CANCEL_REQUEST = 'EVENT_CANCEL_REQUEST';
export const EVENT_CANCEL_SUCCESS = 'EVENT_CANCEL_SUCCESS';
export const EVENT_CANCEL_FAILURE = 'EVENT_CANCEL_FAILURE';

export const EVENT_IMPORT_REQUEST = 'EVENT_IMPORT_REQUEST';
export const EVENT_IMPORT_SUCCESS = 'EVENT_IMPORT_SUCCESS';
export const EVENT_IMPORT_FAILURE = 'EVENT_IMPORT_FAILURE';

export const EVENT_DISMISS_REQUEST = 'EVENT_DISMISS_REQUEST';
export const EVENT_DISMISS_SUCCESS = 'EVENT_DISMISS_SUCCESS';
export const EVENT_DISMISS_FAILURE = 'EVENT_DISMISS_FAILURE';

