import React from "react"
import {NavLink} from "react-router-dom"
import {Circle, Disc, X} from "react-feather"
import classnames from "classnames"
import {useSelector} from "react-redux";
// import logo from '../../../../assets/img/smallLogo.png'


const SidebarHeader = props => {

  const {loading, entity} = useSelector(state => ({
    loading: state.Entity.loading,
    entity: state.Entity.entity,
  }));

  let {
    toggleSidebarMenu,
    activeTheme,
    collapsed,
    toggle,
    sidebarVisibility,
    menuShadow
  } = props;

  const margin_top = entity?.logo_height > 40 ? (entity.logo_height - 40) * -1 : 0

  return <div className="navbar-header">
    <ul className="nav navbar-nav flex-row">
      <li className="nav-item mr-auto">
        <NavLink to="/" className="navbar-brand">
          <div className="Severe"/>
          <img style={{marginTop:margin_top, height: `${entity?.logo_height}px`}} src={entity?.logo} alt={"Logo"} height={60}/>
          {(entity?.show_logo_text === true) && <h2 style={{color: entity?.logo_text_color}} className="brand-text mb-0"> {entity?.logo_text}</h2>}
        </NavLink>
      </li>
      <li className="nav-item nav-toggle">
        <div className="nav-link modern-nav-toggle">
          {collapsed === false ? (
            <Disc
              onClick={() => {
                toggleSidebarMenu(true)
                toggle()
              }}
              className={classnames(
                "toggle-icon icon-x d-none d-xl-block font-medium-4",
                {
                  "text-primary": activeTheme === "primary",
                  "text-success": activeTheme === "success",
                  "text-danger": activeTheme === "danger",
                  "text-info": activeTheme === "info",
                  "text-warning": activeTheme === "warning",
                  "text-dark": activeTheme === "dark"
                }
              )}
              size={20}
              data-tour="toggle-icon"
            />
          ) : (
            <Circle
              onClick={() => {
                toggleSidebarMenu(false)
                toggle()
              }}
              className={classnames(
                "toggle-icon icon-x d-none d-xl-block font-medium-4",
                {
                  "text-primary": activeTheme === "primary",
                  "text-success": activeTheme === "success",
                  "text-danger": activeTheme === "danger",
                  "text-info": activeTheme === "info",
                  "text-warning": activeTheme === "warning",
                  "text-dark": activeTheme === "dark"
                }
              )}
              size={20}
            />
          )}
          <X
            onClick={sidebarVisibility}
            className={classnames(
              "toggle-icon icon-x d-block d-xl-none font-medium-4",
              {
                "text-primary": activeTheme === "primary",
                "text-success": activeTheme === "success",
                "text-danger": activeTheme === "danger",
                "text-info": activeTheme === "info",
                "text-warning": activeTheme === "warning",
                "text-dark": activeTheme === "dark"
              }
            )}
            size={20}
          />
        </div>
      </li>
    </ul>
    <div className={classnames("shadow-bottom", {"d-none": menuShadow === false})}/>
  </div>
}


export default SidebarHeader
