import axios from "axios";

const DEPARTMENT_GET_REQUEST = 'DEPARTMENT_GET_REQUEST';
const DEPARTMENT_GET_SUCCESS = 'DEPARTMENT_GET_SUCCESS';
const DEPARTMENT_GET_FAILURE = 'DEPARTMENT_GET_FAILURE';
const DEPARTMENT_FIND_REQUEST = 'DEPARTMENT_FIND_REQUEST';
const DEPARTMENT_FIND_SUCCESS = 'DEPARTMENT_FIND_SUCCESS';
const DEPARTMENT_FIND_FAILURE = 'DEPARTMENT_FIND_FAILURE';
const DEPARTMENT_ACTION_REQUEST = 'DEPARTMENT_ACTION_REQUEST';
const DEPARTMENT_ACTION_SUCCESS = 'DEPARTMENT_ACTION_SUCCESS';
const DEPARTMENT_ACTION_FAILURE = 'DEPARTMENT_ACTION_FAILURE';

const init = {
  loading: false,
  departments: [],
  department: false,

}
const Departments = (state = init, action) => {
  switch (action.type) {
    case DEPARTMENT_GET_REQUEST: {
      return {...state, loading: true}
    }
    case DEPARTMENT_GET_SUCCESS: {
      return {...state, loading: false, departments: action.payload}
    }
    case DEPARTMENT_GET_FAILURE: {
      return {...state, loading: false}
    }
    case DEPARTMENT_FIND_REQUEST: {
      return {...state, loading: true}
    }
    case DEPARTMENT_FIND_SUCCESS: {
      return {...state, loading: false, department: action.payload}
    }
    case DEPARTMENT_FIND_FAILURE: {
      return {...state, loading: false}
    }
    case DEPARTMENT_ACTION_REQUEST: {
      return {...state, loading: true}
    }
    case DEPARTMENT_ACTION_SUCCESS: {
      return {...state, loading: false}
    }
    case DEPARTMENT_ACTION_FAILURE: {
      return {...state, loading: false}
    }
    default : {
      return {...state}
    }
  }
}


export default Departments;

export const departmentGet = () => dispatch => {
  dispatch({type: DEPARTMENT_GET_REQUEST});
  axios.get('app/departments/get')
    .then(res => dispatch({type: DEPARTMENT_GET_SUCCESS, payload: res.data}))
    .catch(e => dispatch({type: DEPARTMENT_GET_FAILURE, payload: e.message}))
}

export const departmentFind = (id) => dispatch => {
  dispatch({type: DEPARTMENT_FIND_REQUEST});
  axios.get(`app/departments/get/${id}`)
    .then(res => dispatch({type: DEPARTMENT_FIND_SUCCESS, payload: res.data}))
    .catch(e => dispatch({type: DEPARTMENT_FIND_FAILURE, payload: e.message}))
}


export const departmentSubmit = (data) => dispatch => {
  dispatch({type : DEPARTMENT_ACTION_REQUEST})
  axios.post('app/department/submit', data)
    .then(({data}) => dispatch({type: DEPARTMENT_ACTION_SUCCESS,payload: data}) )
    .catch(({message}) => dispatch({type: DEPARTMENT_ACTION_FAILURE,payload: message}) )
}

export const departmentDelete = (data, callBack) => dispatch => {
  dispatch({type : DEPARTMENT_ACTION_REQUEST})
  axios.post('app/departments/delete', data)
    .then(({data}) => dispatch({type: DEPARTMENT_ACTION_SUCCESS,payload: data}) )
    .catch(({message}) => dispatch({type: DEPARTMENT_ACTION_FAILURE,payload: message}) )
    .finally(callBack)
}

export const departmentSetObligations = ({departmentID, obligations}, callBack) => dispatch => {
  let obligationIDs = [];
  (obligations?.selectedRows || []).map(row => obligationIDs.push(row.id));
  dispatch({type: DEPARTMENT_ACTION_REQUEST});
  axios.post('app/departments/obligations_set', {obligationIDs, departmentID})
    .then(res => dispatch({type: DEPARTMENT_ACTION_SUCCESS, payload: res.data}))
    .catch(e => dispatch({type: DEPARTMENT_ACTION_FAILURE, payload: e.message}))
    .finally(() => callBack ? callBack() : null)
}


