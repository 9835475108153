export const REGISTER_SUBMIT_REQUEST = 'REGISTER_SUBMIT_REQUEST'
export const REGISTER_SUBMIT_SUCCESS = 'REGISTER_SUBMIT_SUCCESS'
export const REGISTER_SUBMIT_FAILURE = 'REGISTER_SUBMIT_FAILURE'

export const REGISTER_GET_REQUEST = 'REGISTER_GET_REQUEST'
export const REGISTER_GET_SUCCESS = 'REGISTER_GET_SUCCESS'
export const REGISTER_GET_FAILURE = 'REGISTER_GET_FAILURE'

export const REGISTER_FORM_REQUEST = 'REGISTER_FORM_REQUEST'
export const REGISTER_FORM_SUCCESS = 'REGISTER_FORM_SUCCESS'
export const REGISTER_FORM_FAILURE = 'REGISTER_FORM_FAILURE'

export const REGISTER_FIND_REQUEST = 'REGISTER_FIND_REQUEST'
export const REGISTER_FIND_SUCCESS = 'REGISTER_FIND_SUCCESS'
export const REGISTER_FIND_FAILURE = 'REGISTER_FIND_FAILURE'

export const REGISTER_DELETE_REQUEST = 'REGISTER_DELETE_REQUEST'
export const REGISTER_DELETE_SUCCESS = 'REGISTER_DELETE_SUCCESS'
export const REGISTER_DELETE_FAILURE = 'REGISTER_DELETE_FAILURE'

