import {
  OBLIGATION_ACTION_FAILURE,
  OBLIGATION_ACTION_REQUEST,
  OBLIGATION_ACTION_SUCCESS,
  OBLIGATION_DELETE_FAILURE,
  OBLIGATION_DELETE_REQUEST,
  OBLIGATION_DELETE_SUCCESS,
  OBLIGATION_FIND_FAILURE,
  OBLIGATION_FIND_REQUEST,
  OBLIGATION_FIND_SUCCESS, OBLIGATION_FORM_FAILURE, OBLIGATION_FORM_REQUEST, OBLIGATION_FORM_SUCCESS,
  OBLIGATION_GET_FAILURE,
  OBLIGATION_GET_REQUEST,
  OBLIGATION_GET_SUCCESS,
  OBLIGATION_SUBMIT_FAILURE,
  OBLIGATION_SUBMIT_REQUEST,
  OBLIGATION_SUBMIT_SUCCESS
} from "../../types/obligationTypes";

const initState = {
  loading: false,
  error: false,
  details: {},
  list: false,
}

const Obligations = (state = initState, action) => {
  switch (action.type) {
    case OBLIGATION_GET_REQUEST: {
      return {...state, loading: true}
    }
    case OBLIGATION_GET_SUCCESS: {
      return {...state, loading: false, list: action.payload}
    }
    case OBLIGATION_GET_FAILURE: {
      return {...state, loading: false, error: action.payload}
    }

    case OBLIGATION_FIND_REQUEST: {
      const {details} = state;

      const record = state.details[action.payload] !== 'undefined' ? state.details : Object.assign(details, {[action.payload]: false})

      return {...state, loading: true, details: record}
    }
    case OBLIGATION_FIND_SUCCESS: {
      const {details} = state;
      const record = Object.assign(details, {[action.payload.info.id]: action.payload})
      return {...state, loading: false, details: record}
    }
    case OBLIGATION_FIND_FAILURE: {
      return {...state, loading: false, error: action.payload}
    }

    case OBLIGATION_ACTION_REQUEST: {
      return {...state, loading: true}
    }
    case OBLIGATION_ACTION_SUCCESS: {
      return {...state, loading: false}
    }
    case OBLIGATION_ACTION_FAILURE: {
      return {...state, loading: false}
    }

    case OBLIGATION_SUBMIT_REQUEST: {
      return {...state, loading: true}
    }
    case OBLIGATION_SUBMIT_SUCCESS: {
      return {...state, loading: false, details: action.payload}
    }
    case OBLIGATION_SUBMIT_FAILURE: {
      return {...state, loading: false, error: action.payload}
    }

    case OBLIGATION_DELETE_REQUEST: {
      return {...state, loading: true}
    }
    case OBLIGATION_DELETE_SUCCESS: {
      return {...state, loading: false}
    }
    case OBLIGATION_DELETE_FAILURE: {
      return {...state, loading: false, error: action.payload}
    }

    case OBLIGATION_FORM_REQUEST: {
      return {...state, loading: true}
    }
    case OBLIGATION_FORM_SUCCESS: {
      return {...state, loading: false, details: action.payload}
    }
    case OBLIGATION_FORM_FAILURE: {
      return {...state, loading: false, error: action.payload}
    }


    default :
      return state;
  }
}
export default Obligations;
