import axios from "axios";
import Axios from "axios";

const ENTITY_REQUEST = 'ENTITY_REQUEST';
const ENTITY_SUCCESS = 'ENTITY_SUCCESS';
const ENTITY_FAILURE = 'ENTITY_FAILURE';

const ENTITY_FIND_REQUEST = 'ENTITY_FIND_REQUEST';
const ENTITY_FIND_SUCCESS = 'ENTITY_FIND_SUCCESS';
const ENTITY_FIND_FAILURE = 'ENTITY_FIND_FAILURE';

const ENTITY_ACTION_REQUEST = 'ENTITY_ACTION_REQUEST';
const ENTITY_ACTION_SUCCESS = 'ENTITY_ACTION_SUCCESS';
const ENTITY_ACTION_FAILURE = 'ENTITY_ACTION_FAILURE';

const ENTITY_FORM_REQUEST = 'ENTITY_FORM_REQUEST';
const ENTITY_FORM_SUCCESS = 'ENTITY_FORM_SUCCESS';
const ENTITY_FORM_FAILURE = 'ENTITY_FORM_FAILURE';

const ENTITY_SUBMIT_REQUEST = 'ENTITY_SUBMIT_REQUEST';
const ENTITY_SUBMIT_SUCCESS = 'ENTITY_SUBMIT_SUCCESS';
const ENTITY_SUBMIT_FAILURE = 'ENTITY_SUBMIT_FAILURE';


const initialState = {
  entity: false,
  details: false,
  loading: false,
  error: false,
  form: false,
}

const Entity = (state = initialState, action) => {
  switch (action.type) {
    case ENTITY_REQUEST :
      return {...state, loading: true, errors: false,}
    case ENTITY_SUCCESS :
      return {...state, loading: false, entity: action.payload,}
    case ENTITY_FAILURE :
      return {...state, loading: false, error: action.payload,}

    case ENTITY_FIND_REQUEST :
      return {...state, loading: true, errors: false,}
    case ENTITY_FIND_SUCCESS :
      return {...state, loading: false, details: action.payload,}
    case ENTITY_FIND_FAILURE :
      return {...state, loading: false, error: action.payload,}

    case ENTITY_FORM_REQUEST :
      return {...state, loading: true, errors: false,}
    case ENTITY_FORM_SUCCESS :
      return {...state, loading: false, errors: false, form: action.payload,}
    case ENTITY_FORM_FAILURE :
      return {...state, loading: false, error: action.payload,}

    case ENTITY_SUBMIT_REQUEST :
      return {...state, loading: true, errors: false,}
    case ENTITY_SUBMIT_SUCCESS :
      return {...state, loading: false,errors: false,}
    case ENTITY_SUBMIT_FAILURE :
      return {...state, loading: false, error: action.payload,}

    default : {
      return {...state}
    }
  }
}

export default Entity;

export const getEntity = () => dispatch => {
  dispatch({type: ENTITY_REQUEST})
  axios.get("/entity")
    .then(({data}) => dispatch({type: ENTITY_SUCCESS, payload: data}))
    .catch(({message}) => dispatch({type: ENTITY_FAILURE, payload: message}))
}

export const setEntityFrom = () => dispatch => {
  dispatch({type : ENTITY_FORM_REQUEST})
  axios.get("app/entity/set")
    .then(({data}) => dispatch({type: ENTITY_FORM_SUCCESS, payload: data}))
    .catch(({message}) => dispatch({type: ENTITY_FORM_FAILURE, payload: message}))
}

export const findEntity = () => dispatch => {
  dispatch({type: ENTITY_FIND_REQUEST})
  axios.get("/app/entity/find")
    .then(({data}) => dispatch({type: ENTITY_FIND_SUCCESS, payload: data}))
    .catch(({message}) => dispatch({type: ENTITY_FIND_FAILURE, payload: message}))
}

export const updateEntity = (data) => dispatch => {
  dispatch({type: ENTITY_ACTION_REQUEST})
  let url = "./app/entity/post";
  Axios.post(url, data)
    .then(({data}) => {
      dispatch({type: ENTITY_ACTION_SUCCESS, payload: data})
      dispatch(findEntity())
    })
    .catch(({message}) => dispatch({type: ENTITY_ACTION_FAILURE, payload: message}))
}

export const updateEntityBranding = (data) => dispatch => {
  dispatch({type: ENTITY_ACTION_REQUEST})
  let url = "./app/entity/branding";
  Axios.post(url, data)
    .then(({data}) => {
      dispatch({type: ENTITY_ACTION_SUCCESS, payload: data})
      dispatch(findEntity())
    })
    .catch(({message}) => dispatch({type: ENTITY_ACTION_FAILURE, payload: message}))
}


export const updateEntityProfile = (data) => dispatch => {
  dispatch({type: ENTITY_ACTION_REQUEST})
  let url = "./app/entity/profile";
  Axios.post(url, data)
    .then(({data}) => {
      dispatch({type: ENTITY_ACTION_SUCCESS, payload: data})

    })
    .catch(({message}) => dispatch({type: ENTITY_ACTION_FAILURE, payload: message}))
    .finally(() =>  dispatch(findEntity()))
}

export const updateEntityClassification = (data) => dispatch => {
  dispatch({type: ENTITY_ACTION_REQUEST})
  let url = "./app/entity/profile";
  Axios.post(url, data)
    .then(({data}) => {
      dispatch({type: ENTITY_ACTION_SUCCESS, payload: data})
    })
    .catch(({message}) => dispatch({type: ENTITY_ACTION_FAILURE, payload: message}))
    .finally(() =>  dispatch(findEntity()))

}


export const quickEdit = (data) => dispatch => {
  dispatch({type: ENTITY_SUCCESS, payload: data})
}

export const setTitle = (loading, title) => dispatch => {
  // const {entity, loading} = useSelector(state => ({
  //   entity: state.Entity.entity,
  //   loading: state.Entity.loading
  // }))
  // document.title = `${entity?.title} ${title && " | " + title}`
}


