import { combineReducers } from "redux"
import calenderReducer from "./calendar/"
import emailReducer from "./email/"
import chatReducer from "./chat/"
import todoReducer from "./todo/"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import dataList from "./data-list/"
import Dashboard from "./dashboard/dashboardReducer";
import Obligations from "./obligations/obligationReducer";
import RegisterReducer from "./register/registerReducer";
import ComplianceReducer from "./compliance/omplianceReducers";
import AuthReducer from "./auth/authReducers";
import EventReducer from "./event/eventReducer";
import Templates from "./template/templateReducer";
import Department from "../departments/departmentRegister";
import Entity from "./entity/entityReducer";
import {DepartmentReducer} from "./departments/departmentReducer";

const rootReducer = combineReducers({
  Dashboard: Dashboard,
  Obligations: Obligations,
  Compliance: ComplianceReducer,
  Department: Department,
  Auth: AuthReducer,
  Event: EventReducer,
  Register: RegisterReducer,
  calendar: calenderReducer,
  Templates,
  Entity,
  emailApp: emailReducer,
  todoApp: todoReducer,
  chatApp: chatReducer,
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  dataList: dataList
})

export default rootReducer
